import {
  Directive,
  Input,
  ElementRef,
  inject,
  SimpleChanges,
  DestroyRef,
} from '@angular/core';
import { TranslateService } from '../services/translate.service';
import LanguageService from '../services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserRole } from '../../core/models/user-role.enum';

@Directive({
  selector: '[calculateUserRole]',
  standalone: true,
})
export class CalculateUserRoleDirective {
  @Input('calculateUserRole') permissionScope: UserRole = UserRole.User;
  private translateService = inject(TranslateService);
  private languageService = inject(LanguageService);
  private destroyRef = inject(DestroyRef);
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.switch();
    this.languageService.onLanguageChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.switch();
      });
  }

  switch() {
    let userRole: string;

    switch (this.permissionScope) {
      case UserRole.User:
        userRole = this.translateService.translateInstant(
          'vlc:Admin:Management.Settings.User'
        );
        break;
      case UserRole.WidgetEditor:
        userRole = this.translateService.translateInstant(
          'vlc:Admin:Management.Settings.Widget.Editor'
        );
        break;
      case UserRole.TenantAdmin:
        userRole = this.translateService.translateInstant(
          'vlc:Admin:Management.Settings.Tenant.Admin'
        );
        break;
      case UserRole.GroupAdmin:
        userRole = this.translateService.translateInstant(
          'vlc:Admin:Management.Settings.Group.Admin'
        );
        break;
      case UserRole.SuperAdmin:
        userRole = this.translateService.translateInstant(
          'vlc:Admin:Management.Settings.Super.Admin'
        );
        break;
      default:
        userRole = 'Unknown';
        break;
    }
    this.el.nativeElement.innerText = userRole;
  }
}
